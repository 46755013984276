import {getInfo, login, logout} from '@/api/system/admin'
import {getToken, removeToken, setToken} from '@/utils/auth'

import Ws from '@/utils/websocket'
import router, {resetRouter} from '@/router'

const state = {
  token: getToken(),
  id: null,
  accountType: null,// 账户类型
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  permissions: [],// 拥有的权限码

  isNeedChangePass: false, // 是否必须修改密码
  isValidateCode: false,// 是否校验验证码
  mobile: '',
  systemPath:[]

}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_GROUPID: (state, groupId) => {
    state.groupId = groupId
  },
  SET_GROUPNAME: (state, groupName) => {
    state.groupName = groupName
  },
  SET_GROUPPATH: (state, groupPath) => {
    state.groupPath = groupPath
  },
  SET_POSTID: (state, postId) => {
    state.postId = postId
  },
  SET_POSTCODE: (state, postCode) => {
    state.postCode = postCode
  },
  SET_POSTNAME: (state, postName) => {
    state.postName = postName
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_TENANTCODE: (state, tenantCode) => {
    state.tenantCode = tenantCode
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_ACCOUNT_TYPE: (state, accountType)=>{
    state.accountType = accountType;
  },
  SET_IS_NEED_CHANGE_PASS: (state, isNeedChangePass) => {
    state.isNeedChangePass = isNeedChangePass;
  },
  SET_IS_VALIDATE_CODE: (state, isValidateCode) => {
    state.isValidateCode = isValidateCode;
  },
  SET_MOBILE: (state, mobile) => {
    state.mobile = mobile;
  },
  SET_SYSTEM_PATH: (state, systemPath) => {
    state.systemPath = systemPath;
  }

}

const actions = {
  // user login
  login({commit}, userInfo) {
    const {username, password} = userInfo
    return new Promise((resolve, reject) => {
      login({username: username.trim(), password: password}).then(response => {
        const {data} = response;

        commit('SET_TOKEN', data.token);

        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      });
    })
  },

  // get user info
  getInfo({commit, state}) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const {data} = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const {extendInfo, accountType, userId, roles, name,username,postId,postCode,postName,groupId,groupName,groupPath, avatar, introduction, permissions, mobile,systemPath,tenantCode} = data;

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('此用户暂未分配角色，请联系管理员');
        }

        commit('SET_ACCOUNT_TYPE', accountType);
        commit('SET_ID', userId);
        commit('SET_PERMISSIONS', permissions)
        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_USERNAME', username)
        commit('SET_TENANTCODE', tenantCode)
        commit('SET_POSTID', postId)
        commit('SET_POSTCODE', postCode)
        commit('SET_POSTNAME', postName)
        commit('SET_GROUPID', groupId)
        commit('SET_GROUPNAME', groupName)
        commit('SET_GROUPPATH', groupPath)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)
        commit('SET_MOBILE', mobile);
        commit('SET_IS_NEED_CHANGE_PASS', extendInfo.isNeedChangePass);
        commit('SET_IS_VALIDATE_CODE', extendInfo.isValidateCode);
        commit('SET_SYSTEM_PATH', systemPath);
        if(systemPath!=null && systemPath.length>0){
          var sessionStorageKey = "Ebo-"+'Admin-Token';
         // window.sessionStorage.setItem(sessionStorageKey,state.token);
        }


        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  // 更改头像
  changeAvatar({commit}, avatar) {
    return new Promise((resolve, reject) => {
      commit('SET_AVATAR', avatar)
      resolve()
    })
  },


  // user logout
  logout({commit, state, dispatch}) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        if(Ws){
          Ws.close();
        }
        

        window.localStorage.removeItem('menuReprotTitle');
        window.localStorage.removeItem('userAllMenuColumnData');
        window.localStorage.removeItem('ALL_ENUM');
        window.localStorage.removeItem('menuReprotCodeData');

        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, {root: true})

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({commit}) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({commit, dispatch}, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const {roles} = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {root: true})
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, {root: true})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
